//  =================
//      Imports
//  =================

@import '../../base/base';    // Base Variables

body {
  &.swal2-shown {
    overflow-y: hidden;
  }

  &.swal2-iosfix {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.swal2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  z-index: 1060;

  &.swal2-fade {
    -webkit-transition: background-color .1s;
    transition: background-color .1s;
  }

  &.swal2-shown {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.swal2-modal {
  background-color: $white;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  position: relative;
  max-width: 100%;

  &:focus {
    outline: none;
  }

  &.swal2-loading {
    overflow-y: hidden;
  }

  .swal2-title {
    color: $dark !important;
    font-size: 24px !important;
    text-align: center;
    font-weight: normal;
    text-transform: none;
    position: relative;
    margin: 0 0 .4em;
    padding: 0;
    display: block;
    word-wrap: break-word;
  }

  &.background-modal .swal2-title {
    color: #fff !important;
  }

  .swal2-buttonswrapper {
    margin-top: 15px;

    &:not(.swal2-loading) .swal2-styled[disabled] {
      opacity: .4;
      cursor: no-drop;
    }

    &.swal2-loading {
      .swal2-styled {
        &.swal2-confirm {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 4px solid transparent;
          border-color: transparent;
          width: 40px;
          height: 40px;
          padding: 0;
          margin: 7.5px;
          vertical-align: top;
          background-color: transparent !important;
          background-image: none !important;
          color: transparent;
          cursor: default;
          border-radius: 100%;
          -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
          animation: rotate-loading 1.5s linear 0s infinite normal;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        &.swal2-cancel {
          margin-left: 30px;
          margin-right: 30px;
        }
      }

      :not(.swal2-styled).swal2-confirm::after {
        display: inline-block;
        content: '';
        margin-left: 5px 0 15px;
        vertical-align: -1px;
        height: 15px;
        width: 15px;
        border: 3px solid $m-color_6;
        -webkit-box-shadow: 1px 1px 1px $white;
        box-shadow: 1px 1px 1px $white;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
        animation: rotate-loading 1.5s linear 0s infinite normal;
      }
    }
  }

  .swal2-styled {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $white;
    cursor: pointer;
    padding: 10px 25px !important;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1) !important;
    letter-spacing: 1px;
    font-size: 14px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
  }
}

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  background-image: none !important;
  margin: 0 0.3125em !important;
  padding: 0 !important;
}

.swal2-modal {
  .swal2-confirm.swal2-styled, .swal2-cancel.swal2-styled {}

  .swal2-image {
    margin: 20px auto;
    max-width: 100%;
    width: 50%;
    border-radius: 6px;
  }

  .swal2-close {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    width: 38px;
    height: 40px;
    font-size: 36px;
    line-height: 40px;
    font-family: serif;
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: #cccccc;
    -webkit-transition: color .1s ease;
    transition: color .1s ease;

    &:hover {
      color: #d55;
    }
  }

  > {
    .swal2-input, .swal2-file, .swal2-textarea, .swal2-select, .swal2-radio, .swal2-checkbox {
      display: none;
    }
  }

  .swal2-content {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    position: relative;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: $m-color_16 !important;
    word-wrap: break-word;
  }

  .swal2-input, .swal2-file, .swal2-textarea, .swal2-select, .swal2-radio, .swal2-checkbox {
    margin: 20px auto;
  }

  .swal2-input, .swal2-file, .swal2-textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid $m-color_2;
    -webkit-box-shadow: inset 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    -webkit-transition: border-color box-shadow .3s;
    transition: border-color box-shadow .3s;
  }

  .swal2-input.swal2-inputerror, .swal2-file.swal2-inputerror, .swal2-textarea.swal2-inputerror {
    border-color: $danger !important;
    -webkit-box-shadow: 0 0 2px #f27474 !important;
    box-shadow: 0 0 2px #f27474 !important;
  }

  .swal2-input:focus, .swal2-textarea:focus {
    outline: none;
    border: 1px solid $dark;
    -webkit-box-shadow: 0 0 3px #c4e6f5;
    box-shadow: 0 0 3px #c4e6f5;
  }

  .swal2-file:focus {
    outline: none;
    -webkit-box-shadow: 0 0 3px #c4e6f5;
    box-shadow: 0 0 3px #c4e6f5;
  }

  .swal2-input:focus::-webkit-input-placeholder, .swal2-file:focus::-webkit-input-placeholder, .swal2-textarea:focus::-webkit-input-placeholder, .swal2-input:focus::-moz-placeholder, .swal2-file:focus::-moz-placeholder, .swal2-textarea:focus::-moz-placeholder, .swal2-input:focus:-ms-input-placeholder, .swal2-file:focus:-ms-input-placeholder, .swal2-textarea:focus:-ms-input-placeholder, .swal2-input:focus::placeholder, .swal2-file:focus::placeholder, .swal2-textarea:focus::placeholder {
    -webkit-transition: opacity .3s .03s ease;
    transition: opacity .3s .03s ease;
    opacity: .8;
  }

  .swal2-input::-webkit-input-placeholder, .swal2-file::-webkit-input-placeholder, .swal2-textarea::-webkit-input-placeholder, .swal2-input::-moz-placeholder, .swal2-file::-moz-placeholder, .swal2-textarea::-moz-placeholder, .swal2-input:-ms-input-placeholder, .swal2-file:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder, .swal2-input::placeholder, .swal2-file::placeholder, .swal2-textarea::placeholder {
    color: $m-color_2;
  }

  .swal2-range {
    input {
      float: left;
      width: 80%;
    }

    output {
      float: right;
      width: 20%;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    input, output {
      height: 43px;
      line-height: 41px;
      vertical-align: middle;
      margin: 20px auto;
      padding: 0;
      outline: none;
    }
  }

  .swal2-input {
    height: 43px;
    padding: 0 12px;

    &[type='number'] {
      max-width: 150px;
    }
  }

  .swal2-file {
    font-size: 16px;
  }

  .swal2-textarea {
    height: 108px;
    padding: 12px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    border-radius: 0;
    border: 1px solid $m-color_2;
    -webkit-box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    -webkit-transition: border-color box-shadow .3s;
    transition: border-color box-shadow .3s;
  }

  .swal2-file {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    border-radius: 0;
    outline: none;
    border: 1px solid $m-color_2;
    -webkit-box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.1);
    -webkit-transition: border-color box-shadow .3s;
    transition: border-color box-shadow .3s;
  }

  .swal2-select {
    color: $m-color_6;
    font-size: inherit;
    padding: 5px 10px;
    min-width: 40%;
    max-width: 100%;
    border: 1px solid $m-color_2;
    outline: none;
  }

  .swal2-radio {
    border: 0;

    label:not(:first-child) {
      margin-left: 20px;
    }

    input, span {
      vertical-align: middle;
      outline: none;
    }

    input {
      margin: 0 6px 0 0;
    }
  }

  .swal2-checkbox {
    color: $danger;

    input, span {
      vertical-align: middle;
      outline: none;
    }
  }

  .swal2-validationerror {
    background-color: $m-color_1;
    margin: 0 -20px;
    overflow: hidden;
    padding: 10px;
    color: $m-color_6;
    font-size: 15px;
    font-weight: 300;
    display: none;

    &::before {
      font-family: flaticon !important;
      content: '\f231';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: $danger;
      line-height: 28px;
      text-align: center;
      margin-right: 12px;
      font-size: 24px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range {
    input {
      width: 100% !important;
    }

    output {
      display: none;
    }
  }
}

.swal2-icon {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.swal2-error {
    border-color: $danger !important;
    border: 5px solid $m-color_1 !important;
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);

    .swal2-x-mark {
      position: relative;
      display: block;
    }

    [class^='swal2-x-mark-line'] {
      position: absolute;
      height: 5px;
      width: 47px;
      background-color: $danger !important;
      display: block;
      top: 37px;
      border-radius: 2px;

      &[class$='left'] {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 17px;
      }

      &[class$='right'] {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 16px;
      }
    }
  }

  &.swal2-warning {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $m-color_15 !important;
    border: 5px solid $m-color_1 !important;
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.swal2-info {
    font-family: 'Open Sans', sans-serif;
    color: $primary !important;
    border: 5px solid $m-color_1 !important;
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }

  &.swal2-question {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $secondary !important;
    border: solid 5px $m-color_2 !important;
    border-color: none !important;
    text-align: center;
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
  }

  &:not(.swal2-animate-error-icon):not(.swal2-animate-success-icon) {
    width: 2em !important;
    line-height: 119px !important;
    height: 2em !important;
    margin: 0.25em auto 0.875em !important;
  }
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-icon-text {
  font-size: 5.75rem !important;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86;

  [class^='swal2-success-circular-line'] {
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    &[class$='left'] {
      border-radius: 120px 0 0 120px;
      top: -7px;
      left: -33px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 60px 60px;
      transform-origin: 60px 60px;
    }

    &[class$='right'] {
      border-radius: 0 120px 120px 0;
      top: -11px;
      left: 30px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 0 60px;
      transform-origin: 0 60px;
    }
  }

  .swal2-success-ring {
    width: 80px;
    height: 80px;
    border: 5px solid $m-color_1 !important;
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
  }

  .swal2-success-fix {
    width: 7px;
    height: 90px;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  [class^='swal2-success-line'] {
    height: 5px;
    background-color: $m-color_13 !important;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;

    &[class$='tip'] {
      width: 25px;
      left: 14px;
      top: 46px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &[class$='long'] {
      width: 47px;
      right: 8px;
      top: 38px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

.swal2-progresssteps {
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0;

  li {
    display: inline-block;
    position: relative;
  }

  .swal2-progresscircle {
    background: $primary;
    border-radius: 2em;
    color: $white;
    height: 2em;
    line-height: 2em;
    text-align: center;
    width: 2em;
    z-index: 20;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.swal2-activeprogressstep {
      background: $m-color_13 !important;

      ~ {
        .swal2-progresscircle, .swal2-progressline {
          background: $m-color_2 !important;
        }
      }
    }
  }

  .swal2-progressline {
    background: $primary;
    height: .4em;
    margin: 0 -1px;
    z-index: 10;
  }
}

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent;
}

@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

.swal2-show {
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;

  &.swal2-noanimation {
    -webkit-animation: none;
    animation: none;
  }
}

.swal2-hide {
  -webkit-animation: hideSweetAlert 0.15s forwards;
  animation: hideSweetAlert 0.15s forwards;

  &.swal2-noanimation {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }

  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }

  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.swal2-animate-success-line-tip {
  -webkit-animation: animate-success-tip 0.75s;
  animation: animate-success-tip 0.75s;
}

.swal2-animate-success-line-long {
  -webkit-animation: animate-success-long 0.75s;
  animation: animate-success-long 0.75s;
}

.swal2-success.swal2-animate-success-icon .swal2-success-circular-line-right {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.swal2-animate-error-icon {
  -webkit-animation: animate-error-icon 0.5s;
  animation: animate-error-icon 0.5s;
}

@-webkit-keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.swal2-animate-x-mark {
  -webkit-animation: animate-x-mark 0.5s;
  animation: animate-x-mark 0.5s;
}

@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 575px) {
  .background-modal {
    padding: 5em 4em !important;
  }
}